import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'

import { cn } from '~/utils/misc.tsx'
import { Icon } from '../icons/icon.tsx'
import { type VariantProps, cva } from 'class-variance-authority'

export type CheckboxProps = Omit<
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
  'type'
> & {
  type?: string
} & VariantProps<typeof checkboxVariants>

const checkboxVariants = cva(
  'peer h-4 w-4 shrink-0 rounded-sm border  ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      color: {
        primary:
          'border-primary data-[state=checked]:bg-primary-300 data-[state=checked]:text-primary-300',
        secondary:
          'border-secondary data-[state=checked]:bg-secondary data-[state=checked]:text-secondary',
      },
    },
    defaultVariants: {
      color: 'primary',
    },
  },
)

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> &
    VariantProps<typeof checkboxVariants>
>(({ className, color = 'primary', ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(checkboxVariants({ color, className }))}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn('flex items-center justify-center text-white')}
    >
      <Icon name="check" size="xs" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
